::selection {
  background-color: #061e64;
  color: #ffffff;
}

.theme_main_heading {
  font-size: 60px;
  font-family: "oxygen";
  font-weight: 600;
}

.theme_heading_regular {
  font-size: 50px;
  font-weight: 700;
}
/* #00CCFF,#2D68C4, #061E64, #00BFFF */
.theme_heading {
  background: #061e64;
}

.theme_heading_after {
  margin-bottom: 30px;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 2.5555em;
  color: #0256a3;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

.theme_heading_after::after {
  content: "\25CF \25CF \25CF";
  font-size: 1rem;
  line-height: 0;
  letter-spacing: 0.25em;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.theme_para {
  font-size: 24px;
  font-family: "oxygen";
}

.theme_bg {
  background: #061e64;
}

.theme_border {
  border: 1px solid #061e64;
}

.theme_color {
  color: #061e64;
}

.theme_btn {
  color: #ffffff;
  border-color: #061e64;
  font-size: 15px !important;
  font-weight: bold !important;
  text-transform: uppercase;
  background-color: #061e64;
  padding: 12px 16px 10px;
  min-width: 90px;
  border-radius: 3px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 3px 0;
  line-height: 1.2em;
  position: relative;
  text-align: center;
  max-width: 100%;
}

.theme_btn:hover {
  background-color: #061e64 !important;
  border-color: #061e64;
}

.home_banner {
  min-height: 650px;
}

.bg_opacity {
  background: rgb(0, 0, 0, 0.55);
}

.w_50 {
  width: 100%;
}

/* home section 2 style */
.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #fff;
  border-radius: 0.75rem;
}

.icon-link {
  display: inline-flex;
  align-items: center;
}
.icon-link > .bi {
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  transition: transform 0.25s ease-in-out;
  fill: currentColor;
}
.icon-link:hover > .bi {
  transform: translate(0.25rem);
}

.icon-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: 0.75rem;
}

.rounded-4 {
  border-radius: 0.5rem;
}
.rounded-5 {
  border-radius: 1rem;
}

.text-shadow-1 {
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}
.text-shadow-2 {
  text-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}
.text-shadow-3 {
  text-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
}

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
  .w_50 {
    width: 50%;
  }
}

/* home section 3 style */
.container_foto {
  background-color: rgba(57, 62, 93, 0.7);
  padding: 0;
  overflow: hidden;
}

.container_foto article {
  width: 100%;
  padding: 10%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.container_foto h2 {
  color: #fff;
  font-weight: 800;
  font-size: 25px;
  border-bottom: #fff solid 1px;
}

.container_foto h4 {
  font-weight: 300;
  color: #fff;
  font-size: 16px;
}

.container_foto img {
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.4;
  -webkit-transition: all 4s ease;
  -moz-transition: all 4s ease;
  -o-transition: all 4s ease;
  -ms-transition: all 4s ease;
  transition: all 4s ease;
}

.ver_mas {
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transform: translate(0px, 70px);
  -webkit-transform: translate(0px, 70px);
  -moz-transform: translate(0px, 70px);
  -o-transform: translate(0px, 70px);
  -ms-transform: translate(0px, 70px);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.ver_mas a {
  font-size: 18px;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

/*hovers*/
.container_foto:hover {
  cursor: pointer;
}

.container_foto:hover img {
  opacity: 0.1;
  transform: scale(1.5);
}

.container_foto:hover article {
  transform: translate(2px, -69px);
  -webkit-transform: translate(2px, -69px);
  -moz-transform: translate(2px, -69px);
  -o-transform: translate(2px, -69px);
  -ms-transform: translate(2px, -69px);
}

.container_foto:hover .ver_mas {
  transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  opacity: 1;
}

/* Footer style */

.footer-distributed {
  background: #061e64;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 40%;
}
/* The company logo */
.footer-distributed h3 {
  color: #ffffff;
  font: normal 36px "Open Sans", cursive;
  margin: 0;
}

.footer-distributed h3 span {
  color: #000;
}

/* Footer links */
.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #fff;
  color: #061e64;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: #fff;
  text-decoration: none;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 20%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #fff;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 2px;

  font-size: 20px;
  color: #061e64;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}

/* about-us page style */
.sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #061e64;
  font-weight: 500;
  margin-bottom: 15px;
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 1.28em;
  color: #222222;
  font-weight: 600;
  padding-bottom: 18px;
}

.sec-title h2:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 3px;
  background-color: #d1d2d6;
}

.sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
  margin-top: 35px;
}

.sec-title.light h2 {
  color: #ffffff;
}

.sec-title.text-center h2:before {
  left: 50%;
  margin-left: -25px;
}

.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #222222;
  font-weight: 400;
  padding-left: 35px;
  margin-bottom: 12px;
}

.list-style-one li:before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 0px;
  display: block;
  font-size: 18px;
  padding: 0px;
  color: #ff2222;
  font-weight: 600;
  -moz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1.6;
  font-family: "Font Awesome 5 Free";
}

.list-style-one li a:hover {
  color: #44bce2;
}

.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #061e64;
}

.btn-style-one:hover {
  background-color: #0794c9;
  color: #ffffff;
}

.about-section {
  position: relative;
  padding: 120px 0 70px;
}

.about-section .sec-title {
  margin-bottom: 45px;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-left: 30px;
}

.about-section .text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
}

.about-section .list-style-one {
  margin-bottom: 45px;
}

.about-section .btn-box {
  position: relative;
}

.about-section .btn-box a {
  padding: 15px 50px;
}

.about-section .image-column {
  position: relative;
}

.about-section .image-column .text-layer {
  position: absolute;
  right: -110px;
  top: 50%;
  font-size: 325px;
  line-height: 1em;
  color: #ffffff;
  margin-top: -175px;
  font-weight: 500;
}

.about-section .image-column .inner-column {
  position: relative;
  padding-left: 80px;
  padding-bottom: 0px;
}

.about-section .image-column .inner-column .author-desc {
  position: absolute;
  bottom: 16px;
  z-index: 1;
  background: orange;
  padding: 10px 15px;
  left: 96px;
  width: calc(100% - 152px);
  border-radius: 50px;
}

.about-section .image-column .inner-column .author-desc h2 {
  font-size: 21px;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  margin: 0;
}

.about-section .image-column .inner-column .author-desc span {
  font-size: 16px;
  letter-spacing: 6px;
  text-align: center;
  color: #fff;
  display: block;
  font-weight: 400;
}

.about-section .image-column .inner-column:before {
  content: "";
  position: absolute;
  width: calc(50% + 80px);
  height: calc(100% + 160px);
  top: -80px;
  left: -3px;
  background: transparent;
  z-index: 0;
  border: 44px solid #061e64;
}

.about-section .image-column .image-1 {
  position: relative;
}

.about-section .image-column .image-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img {
  box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
  border-radius: 46px;
}

.about-section .image-column .video-link {
  position: absolute;
  left: 70px;
  top: 170px;
}

.about-section .image-column .video-link .link {
  position: relative;
  display: block;
  font-size: 22px;
  color: #191e34;
  font-weight: 400;
  text-align: center;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.about-section .image-column .video-link .link:hover {
  background-color: #191e34;
  color: #fff;
}

/* contact page style */
.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  max-width: 840px;
}

/* Left contact page */
.form-horizontal {
  /*float: left;*/
  max-width: 400px;
  font-family: "Lato";
  font-weight: 400;
}

.form-control,
textarea {
  /* max-width: 400px; */
  /* background-color: #000; */
  /* color: #fff; */
  letter-spacing: 1px;
  margin-bottom: 12px;
}

.send-button {
  margin-top: 15px;
  height: 34px;
  width: 400px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.alt-send-button {
  width: 400px;
  height: 34px;
  transition: all 0.2s ease-in-out;
}

.send-text {
  display: block;
  margin-top: 10px;
  font: 700 12px "Lato", sans-serif;
  letter-spacing: 2px;
}

.alt-send-button:hover {
  transform: translate3d(0px, -29px, 0px);
}

/* Begin Right Contact Page */
.direct-contact-container {
  max-width: 400px;
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  margin-left: 0;
  padding-right: 20px;
  text-align: left;
}

.list-item {
  line-height: 25px;
  color: #061e64;
  margin-bottom: 15px;
}

.list-item .fa {
  min-width: 30px;
  margin-right: 12px;
}

.contact-text {
  font: 300 18px "Lato", sans-serif;
  letter-spacing: 1.9px;
  color: #000;
}

.contact-text a {
  color: #000;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #061e64;
  text-decoration: none;
}

/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(27, 27, 27);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #061e64;
}
/* #00CCFF,#2D68C4, #061E64, #00BFFF
.social-media-list li:hover {
	background-color: #00BFFF;
} */

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

.social-media-list li:hover a {
  color: #fff;
}

.copyright {
  font: 200 14px "Oswald", sans-serif;
  color: #000;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
}

.card {
  min-height: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #061e64;
  color: #fff;
}

.card:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.z-index {
  z-index: 100000;
}

.background_color {
  background: linear-gradient(to left, #061e64, #0074cc);
}

.card_border {
  min-height: 470px;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-bottom: 20px solid #061e64;
  margin-top: 15px;
}

.card_border:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.padding {
  margin-top: 30px;
  margin-bottom: 30px;
}

.paddingSmall {
  margin-top: 10px;
  margin-bottom: 10px;
}

.lead {
  margin-top: 40px;
  line-height: 34px;
  font-size: 24px;
}

.cardTxtColor {
  color: #0256a3;
  font-size: 28px;
  margin-top: 10px;
}
.cardSubTxt {
  font-size: 16px;
  color: #363636;
  margin-top: 20px;
  margin-bottom: 20px;
}

.learnMore {
  font-size: 18px;
  color: #0256a3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 20px;
}

.no-underline {
  text-decoration: none;
}

.service-link {
  display: flex;
  justify-content: center;
}

.button {
  border-radius: 10px;
  background-color: #e6d568;
  color: #0256a3;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  padding: 20px 25px;
}

.button:hover {
  background: #0256a3;
  color: #ffffff;
  transition-duration: 0.5s;
  transition-delay: ease-in-out;
}

/* Begin Media Queries*/
@media screen and (max-width: 850px) {
  .contact-wrapper {
    display: flex;
    flex-direction: column;
  }

  .direct-contact-container,
  .form-horizontal {
    margin: 0 auto;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 300px;
  }

  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }

  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

@media screen and (max-width: 569px) {
  .direct-contact-container,
  .form-wrapper {
    float: none;
    margin: 0 auto;
  }

  .name,
  .email,
  textarea {
    width: 280px;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 280px;
  }

  .social-media-list {
    left: 0;
  }

  .social-media-list li {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 2rem;
  }

  .social-media-list li:after {
    width: 55px;
    height: 55px;
    line-height: 55px;
  }
}

@media screen and (max-width: 410px) {
  .send-button {
    width: 99%;
  }
}

@media only screen and (max-width: 767px) {
  .about-section {
    padding: 60px 0 60px;
  }
  .about-section .image-column .inner-column:before {
    width: calc(50% + 50px);
    height: calc(100% + 100px);
    top: -53px;
    border: 25px solid #061e64;
  }
  .about-section .image-column .inner-column {
    margin-bottom: 80px;
  }
  .about-section .content-column .inner-column {
    padding-left: 0;
  }
  .about-section .content-column {
    margin-bottom: 0;
  }
  .sec-title h2 {
    font-size: 32px;
  }
  .about-section .sec-title {
    margin-bottom: 25px;
  }
  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    text-align: left;
    margin-bottom: 20px;
  }
  .footer-distributed {
    padding: 35px;
  }
  ul.navbar-nav.ms-auto.mb-2.mb-lg-0 {
    text-align: left;
  }
}
