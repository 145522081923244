/* .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */

.link-hover:hover {
  color: #e6d568;
}
.header {
  width: 100%;
}
.header.sticky nav {
  position: fixed;
  top: 0;
  height: 140px;
  padding: 0;
  background: #fff;
}
.btn_contact {
  background: #e6d568;
  width: 100%;
  max-width: 180px;
  height: 48px;
  font-size: 20px;
  font-weight: 500;
  color: #0256a3;
  border: 0;
  border-radius: 5px;
}

.btn_contact:hover {
  background: #0256a3;
  color: #e6d568;
  transition-duration: 0.5s;
  transition-delay: ease-in-out;
}

.btn_banner {
  border-radius: 10px;
  background-color: #e6d568;
  color: #0256a3;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px 25px;
}

.btn_banner:hover {
  color: #ffffff;
  transition-duration: 0.5s;
  transition-delay: ease-in-out;
}

.navbar-brand img {
  width: 100%;
  max-width: 270px;
  height: 100px;
}

.nav-item .nav-link {
  font-size: 18px;
  color: black;
}

.bg-colored {
  width: 40%;
  transform: translateY(-50%);
  color: #fff;
  background-color: rgba(25, 101, 169, 0.9);
  padding: 40px 30px;
}

.bg-theme-blue-section-4 {
  background: #0256a3;
  padding: 90px 0;
}

.bg-theme-blue-section-4 h2 {
  font-size: 40px;
  font-family: "oxygen";
  font-weight: 600;
}

.law-firm-img {
  background: url("../src/assets/imgs/law-firm.jpg");
  width: 25%;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
}

.medical-health-img {
  background: url("../src/assets/imgs/medical-e1693574906821.jpg");
  width: 25%;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
}

.plumbing-img {
  background: url("../src/assets/imgs/hvac-e1693574967168.jpg");
  width: 25%;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
}

.property-manage-img {
  background: url("../src/assets/imgs/property-mgt.jpg");
  width: 25%;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
}

.remediation-img {
  background: url("../src/assets/imgs/remediation.jpg");
  width: 25%;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
}

.industry-img {
  background: url("../src/assets/imgs/other-industries.jpg");
  width: 25%;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
}

.clients-content-style {
  padding: 10px;
}

.clients-content-style h3 {
  font-size: 30px;
  font-family: 600;
  color: #0256a3;
}

.clients-content-style p {
  font-size: 18px;
  color: #363636;
  font-family: "oxygen";
}

.home-section5-bg {
  padding: 38px 0 90px;
}

.representative-content {
  width: 100%;
  padding: 62px;
}

.representative-content h2 {
  font-size: 40px;
  font-family: "oxygen";
  font-weight: 600;
  color: #0256a3;
}

.representative-content span {
  font-size: 18px;
  font-family: "oxygen";
  color: #363636;
}

.logos {
  padding: 90px 0;
  margin-top: -90px;
  background-color: #dedede;
}

.logo-repeater {
  margin-top: 30px;
}

.logos h2 {
  font-size: 40px;
  font-family: "oxygen";
  font-weight: 600;
  color: #0256a3;
  text-align: center;
}

.repeater {
  list-style: none;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.hp-blogs {
  padding: 90px 0 40px;
}

.hp-blogs .container .inner-content h2 {
  color: #0256a3;
  font-size: 40px;
  text-align: center;
}

.hp-blogs .container .inner-content .blogs {
  margin-top: 40px;
}

.hp-blogs .container .inner-content .blogs ul {
  list-style: none;
  display: flex;
  width: 100%;
  margin: 0;
  gap: 50px;
  flex-wrap: wrap;
  row-gap: 120px;
  justify-content: center;
}

.hp-blogs .container .inner-content .blogs ul li {
  position: relative;
}

.blog-img {
  position: relative;
  max-width: 402px;
  width: 100%;
  height: 100%;
  max-height: 311px;
}

.blog-img-style {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.blog-inner-content {
  background-color: #f1f1f1;
  position: absolute;
  width: 85%;
  padding: 30px;
  border-radius: 10px;
  left: 34px;
  bottom: -65px;
}

.blog-inner-content h3 {
  font-size: 21px;
  font-family: "oxygen";
  font-weight: 600;
  color: #363636;
}

.blog-date {
  position: absolute;
  right: 7px;
  bottom: -66px;
}

.blog-date span {
  color: #fff;
  background-color: #0256a3;
  padding: 15px;
  border-radius: 10px;
}

.blog-link {
  margin-top: 160px;
}

.button-blog {
  border-radius: 10px;
  background-color: #e6d568;
  color: #0256a3;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  padding: 20px 50px;
}

.button-blog:hover {
  background: #0256a3;
  color: #ffffff;
  transition-duration: 0.5s;
  transition-delay: ease-in-out;
}

.contact {
  padding: 60px 0 0;
}

.contact .row {
  --bs-gutter-x: 0;
}

.contact-img {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 600px;
  max-height: 100%;
}

.contact-bg-img {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 600px;
  max-height: 100%;
}

.blog-question {
  position: absolute;
  top: 100px;
  left: 0px;
}

.blog-question h2 {
  font-size: 40px;
  font-family: "oxygen";
  font-weight: 600;
  color: #fff;
}

.blog-question p {
  font-size: 24px;
  font-family: "oxygen";
  color: #ffffff;
}

.button-contact-us {
  border-radius: 10px;
  background-color: #e6d568;
  color: #0256a3;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  padding: 20px 30px;
}

.button-contact-us:hover {
  background: #f9f0b5;
  color: #0256a3;
  transition-duration: 0.5s;
  transition-delay: ease-in-out;
}

.footer {
  padding: 60px 0;
}

.border-color {
  color: #0256a3;
  border: 1px solid #0256a3;
}

.footer-address-info h3 {
  font-size: 24px;
  font-family: "oxygen";
  font-weight: 600;
  color: #0256a3;
}

.footer-address-info p {
  color: #606060;
  font-size: 18px;
  font-family: 'oxygen';
  font-weight: 500;
}

.footer-phone p {
  color: #606060;
  font-size: 18px;
  font-family: 'oxygen';
  font-weight: 500;
}

.expolore h3 {
  font-size: 24px;
  font-family: "oxygen";
  font-weight: 600;
  color: #0256a3;
}

.expolore ul li {
  color: #606060;
  font-size: 18px;
  font-family: 'oxygen';
  font-weight: 500;
}

.bg-copyright {
  background: #0256a3;
}

@media (max-width: 991.98px) {
  .top-bar {
    flex-direction: column !important;
    align-items: center !important;
  }
  .w-banner-1 {
    height: 400px;
  }
  .link-main {
    flex-direction: column !important;
    align-items: center !important;
  }
  .bg-colored {
    width: 100%;
    margin-left: 0 !important;
    padding: 30px;
  }
  .theme_main_heading {
    font-size: 28px !important;
  }
  .bg-theme-blue-section-4 {
    padding: 60px 0;
  }
  .bg-theme-blue-section-4 h2 {
    font-size: 32px;
  }
  .representative-content {
    padding: 0;
  }
  .logos .container .logo-repeater ul {
    flex-wrap: wrap;
  }
  .mt-in-responsive {
    margin: 50px 0;
  }
  .contact-img {
    height: 100%;
  }
  .contact-bg-img {
    height: 100%;
  }
  .blog-question {
    top: 40px;
  }
  .blog-question h2 {
    font-size: 30px;
  }
  .blog-question p {
    font-size: 20px;
  }
  .m-bottom-responsive {
    margin-bottom: 20px;
  }
}
